<template>
  <va-card v-if="showGrid" title="Organization">
    <template slot="actions">
      <va-button color="success" icon="fa fa-plus" @click="showGrid = false">Add</va-button>
      <va-button color="orange" v-if="isEnable" icon="entypo entypo-export" @click="exportData()">Export</va-button>
    </template>

    <div class="row align--center">
      <div class="flex xs12 md6">
        <va-input
          :value="term"
          placeholder="search"
          @input="search"
          removable>
          <va-icon name="fa fa-search" slot="prepend" />
        </va-input>
      </div>
    </div>
    <va-data-table
      :fields="fields"
      :data="filteredData"
      :per-page="parseInt(perPage)">
      <template slot="status" slot-scope="props">
        <i v-if="props.rowData.status == 1" class='fa fa-check-circle status-true'/>
        <i v-else class='fa fa-times-circle status-false'/>
      </template>
      <template slot="edit" slot-scope="props">
        <va-button flat small 
        color="gray" icon="fa fa-pencil" 
        @click="editOrg(props.rowData)" 
        class="ma-0">
        </va-button>
      </template>
    </va-data-table>
  </va-card>
  <va-card v-else :title="isCreate?'create organization':'update organization'">
    <template slot="actions">
      <va-button icon="fa fa-list"
        @click="resetValue()" color="gray">List</va-button>
    </template>
    <form @submit.prevent="isCreate?addOrg():updateOrg()">
      <va-input
        label="Organization Name *"
        pattern="^[A-Za-z0-9_-]+(?: +[A-Za-z0-9_-]+)*$"
        v-model="org.org_name"
        type="text"
        placeholder="Enter Organization Name"
        required="true"
      />
      <va-select
        label="Type *"
        v-model="org.org_type"
        :options="typeOtns"
        placeholder="Select Type"
        required="true"
        noClear
      />
      <va-input
        label="Technical Email *"
        v-model="org.technical_email"
        type="email"
        pattern="^[a-z0-9.]+@[a-z0-9.]+\.[a-z]{1,4}$"
        placeholder="Enter Email ID"
        required="true"
      />
      <va-input
        label="Accounts Email *"
        v-model="org.accounts_email"
        type="email"
        pattern="^[a-z0-9.]+@[a-z0-9.]+\.[a-z]{1,4}$"
        placeholder="Enter Email ID"
        required="true"
      />
      <va-input
        label="Mobile Number *"
        v-model="org.mobile_number"
        pattern="[6-9]{1}[0-9]{9}"
        maxlength="10"
        placeholder="Enter Mobile Number"
        required="true"
      />
      <va-input
        label="Address *"
        v-model="org.address"
        type="text"
        placeholder="Enter Address"
        required="true"
      />
      <va-select
        label="Country *"
        textBy="name"
        searchable
        placeholder="Select Country"
        v-model="org.country"
        :options="countries"
        noClear
      />    
      <va-select
        label="State *"
        searchable
        textBy="name"
        placeholder="Select State"
        v-if="states.length && org.country"
        v-model="org.state"
        :options="states"
        noClear
      />   
      <va-select
        label="City *"
        searchable
        textBy="name"
        placeholder="Select City"
        v-if="cities.length && org.state"
        v-model="org.city"
        :options="cities"
        noClear
      />
      <va-select
        label="Invoicing Method *"
        v-model="org.invoicing_method"
        :options="invoiceOtns"
        placeholder="Select Invoicing Method"
        required="true"
        noClear
      />
      <div
        class="fields"
        v-for="(field,i) in platformDetails"
        :key="i">
        <va-select
          label="Platform *"
          searchable
          placeholder="Select Platform"
          v-model="field.platform_name"
          textBy="platform_name"
          :options="platformOtns"
          required="true"
          noClear
        />
        <va-select
          label="Installation Type *"
          placeholder="Select Installation Type"
          v-model="field.installation_type"
          textBy="id"
          :options="installationTypeOtns"
          required="true"
          noClear
        />
        <va-button
          class="content_width toCenter"
          flat color="primary"
          @click.prevent="fields_length++"
          icon="entypo entypo-plus" />
        <va-button
          v-if="platformDetails.length != 1"
          class="content_width toCenter"
          flat color="danger"
          @click.prevent="removeField(i)"
          icon="entypo entypo-cancel" />
      </div>
      <va-checkbox
        label="Status"
        v-model="org.status"
      />
      <div class="d-flex justify--center mt-3">
        <va-button class="my-0" flat @click="resetValue()">Cancel</va-button>
        <va-button type="submit" class="my-0">{{isCreate?'Create':'Update'}}</va-button>
      </div>
    </form>
  </va-card>
</template>

<script>
import { debounce } from 'lodash'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { exportToFile } from '../../i18n/exportFile.js'

Vue.use(vueResource)

export default {
  created(){
    this.getOrgs()
    this.getAllCountries()
  },
  data () {
    return {
      term:null,
      isEnable:false,
      perPage:'10',
      perPageOptions:['4', '6', '10', '20'],
      showGrid:true,
      isCreate:true,
      fields_length : 0,
      typeOtns:["RESELLER"],
      invoiceOtns:["CUSTOMER","ORG"],
      platformOtns:[],
      platformDetails:[{platform_name:'',installation_type:''}],
      org_id:'',
      orgs: [],
      installationTypeOtns:[{id:'SINGLE'},{id:'MULTIPLE'}],
      countries : [],
      states : [],
      cities : [],
      isfirst : {
        state : false,
        city : false
      },
      org:{
        org_name:"",
        org_type:"",
        technical_email:"",
        accounts_email:"",
        mobile_number:"",
        address:"",
        country:"",
        state:"",
        city:"",
        invoicing_method:"",
        status:true,
        org_platforms:[],
      },
    }
  },
  watch:{
    'org.country'(val){
      this.org.state = this.isfirst.state ? this.org.state : ""
      this.isfirst.state = false
      val && this.getStates(val.country_id)
      if (!val)
        this.states = []
    },
    'org.state'(val){
      this.org.city = this.isfirst.city ? this.org.city : ""
      this.isfirst.city = false
      val && this.getCities(this.org.country.country_id,val.state_id)
      if (!val)
        this.cities = []
    },
    fields_length () {
      this.platformDetails.push({
        platform_name:'',
        installation_type:''
      })
    },
  },
  computed: {
    fields () {
      return [
        {
          name: 'sno',
          width: "5%"
        },
        {
          name: 'org_name',
          title: 'Name',
          width: "15%"
        },
        {
          name: 'org_type',
          title: 'TYPE',
          width: "7%"
        },
        {
          name: 'mobile_number',
          title: 'Mobile Number',
          width: "10%"
        },
        {
          name: 'country.name',
          title: 'Country',
          width: "5%"
        },
        {
          name: '__slot:status',
          title: 'Status',
          width: "5%"
        },        
        {
          name: '__slot:edit',
          width: "5%"
        }
      ]
    },
    filteredData () {
      var filteredOrg = search(this.term, this.orgs)
      return filteredOrg
    }
  },
  methods:{
    removeField (index) {
      this.platformDetails = this.platformDetails.filter((_field, i) => i != index)
    },
    getCities(val1,val2){
      this.$http.get(`${config.menu.host}customer_org/country/${val1}/state/${val2}`)
      .then(res => {
        res.body.map((item) => {
          item.id=item.city_id
        })
        this.cities = res.body
      })
    },
    getStates(val){
      this.$http.get(`${config.menu.host}customer_org/country/${val}`)
      .then(res => {
        res.body.map((item) => {
          item.id=item.state_id
        })
        this.states = res.body
      })
    },
    getAddressDet(){
      for (let i = 0; i < this.orgs.length; i++) {
        const org = this.orgs[i];
        this.$http.get(`${config.menu.host}customer_org/country/${org.country}/state/${org.state ? org.state : 0}/city/${org.city ? org.city : 0}`)
        .then(res => {
          org.country = res.data.country
          org.state = res.data.state
          org.city = res.data.city
        })
      }
    },
    getAllCountries(){
      this.$http.get(config.menu.host+'customer_org/countries')
      .then(res => {
        res.body.map((item) => {
          item.id=item.country_id
        })
        this.countries = res.body
      })
    },
    exportData(){
      var rowKeys = {
        org_name: 'Organization Name',
        org_type: 'Type',
        state: 'State',
        mobile_number: 'Mobile Number'
      }
      return exportToFile(this.orgs, rowKeys, 'Organization', 'CSV')
    },
    editOrg(org){
      this.org = org
      this.org.org_platforms = org.org_platforms
      this.platformDetails = this.org.org_platforms
      Object.entries(this.org).map(key => {
        key = key[0]
        if(key == 'status'){
          this.org[key] =  org[key]? 
          true : false
        }   
        if (key) this.org[key] = org[key]
      })
      this.org_id = org.org_id
      this.showGrid = false
      this.isCreate = false
      this.isfirst.state = true
      this.isfirst.city = true
    },
    resetValue(){
      this.showGrid = true
      this.isCreate = true
      Object.entries(this.org).forEach(key => {
        if (key[0] == 'org_platforms') this.org[key[0]] = []
        else if (key[0] == 'status') this.org[key[0]] = true
        else this.org[key[0]] = ''
      })
    },
    getOrgs(){
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host+'org')
      .then(res => {
        loader.hide()
        this.orgs = res.data
        this.isEnable = (this.orgs.length > 0) ? true : false
        this.orgs.map((org,i) => org.sno = i+1)
        this.getAllPlatform()
        this.getAddressDet()
      })
      .catch(error => {
        loader.hide()
        Vue.notify({text: error.body,type: 'error'})
      })
    },
    getAllPlatform(){
      this.$http.get(config.menu.host+'platform')
      .then(res => {
        let platform_ids = this.orgs.map(org => org.platform_id)
        this.platformOtns = res.data.filter(data => !platform_ids.includes(data.platform_id))
        this.platformOtns.map((platform,i) => platform.id = ++i)
      })
    },
    validation(){
      for (const key in this.org) {
        if (key == 'state' && !this.states.length) {
          continue
        }
        if (key == 'city' && !this.cities.length) {
          continue
        }
        if (key != 'status' && this.org[key] == "") {
          Vue.notify({ text: "Please fill the form correctly", type: 'error'})
          return false
        }
      }
      return true
    },
    addOrg(){
      this.org.org_platforms = this.platformDetails.map(function(item){
        return {
          platform_id       : item.platform_name.platform_id,
          platform_name     : item.platform_name.platform_name,
          installation_type : item.installation_type.id 
        }
      }) 
      if (!this.validation()) return
             
      let payload = { ...this.org }

      payload.country = payload.country ? payload.country.country_id : ""
      payload.state   = payload.state ? payload.state.state_id : ""
      payload.city    = payload.city ? payload.city.city_id : ""

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host+'org',payload)
      .then(function(res){
        loader.hide()
        Vue.notify({text: res.body,  type: 'success'})
        this.resetValue()
        this.getOrgs()
      }, function(err){
        loader.hide()
        Vue.notify({ text: err.body, type: 'error'})
      })
    },
    updateOrg(){
      var entity = this.org
      this.org.org_platforms = this.platformDetails.map(function(item){
        return {
          platform_id: item.platform_name.platform_id ? item.platform_name.platform_id : item.platform_id,
          platform_name : item.platform_name.platform_name ? item.platform_name.platform_name : item.platform_name,
          installation_type : item.installation_type.id ? item.installation_type.id :  item.installation_type,
          org_id : entity.org_id
        }
      }) 
      if (!this.validation()) return
      
      let payload = { ...this.org }

      payload.country = payload.country ? payload.country.country_id : ""
      payload.state   = payload.state ? payload.state.state_id : ""
      payload.city    = payload.city ? payload.city.city_id : ""

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host+`org/${this.org_id}`,payload)
      .then(res => {
        loader.hide()
        Vue.notify({text: res.body, type: 'success'})
      })
      .catch(err => {
        loader.hide()
        Vue.notify({ text: err.body, type: 'error'})
      })
      .then(() => {
        this.resetValue()
        this.getOrgs()
      })
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  }
}
</script>

<style scoped>
.badge {
  padding: 8px 0.5rem;
}

.status-true {
  color: green;
  padding-left: 1rem;
}

.status-false {
  color: red;
  padding-left: 1rem;
}

.fields {
  display: grid;
  grid-template-columns: 6fr 6fr 6fr 6fr 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 2rem;
}

.content_width {
  width: 30px;
  height: 30px;
}
</style>
